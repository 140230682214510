import { admin } from '@/settings/Roles';
import Packages from '@/util/GeneralInformation';
import Products from '@/util/Products';
import {
	NON_AUTH_HIDDEN_PRODUCTS,
	PRODUCT_DETAILS_LINKS as productDetailLinks,
	PRODUCT_DEFAULT_PRICE,
	PRODUCT_OVERRIDE_PRICE,
	STORE_PRODUCT_SORT_ALL as sortListAll,
	STORE_PRODUCT_SORT_NOT_ALL as sortListNotAll, // If any main product that is not VR, is missing
	AGENCY_PRODUCTS,
} from '@/settings/Products';

export default {
	data() {
		return {
			admin,
			packData: new Packages(),
			prodData: new Products(),
			bundlesInfo: new Products(),
			freeVirtualEventsInfo: new Products(),
			currentPackage: '',
		};
	},
	computed: {
		packages() {
			try {
				return this.packData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		pack() {
			try {
				return this.packages.find((item) => item.attributes.code_name === this.currentPackage);
			} catch (error) {
				return {};
			}
		},
		loadingProducts() {
			try {
				if (this.isAgencySite) {
					return false;
				}
				return this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasProducts() {
			return !!this.products.length;
		},
		storeProducts() {
			try {
				const { data } = this.isAgencySite ? AGENCY_PRODUCTS : this.prodData.data.response.data;
				return this.updateManyAgencyProductsPrice(data);
			} catch (error) {
				return [];
			}
		},
		products() {
			try {
				let data = this.storeProducts.filter((product) => product.attributes.category_code === 'main');

				if (!this.$user.auth()) {
					data = data.filter((product) => !NON_AUTH_HIDDEN_PRODUCTS.includes(product.attributes.sku));
				}

				data = data.map((product) => {
					if (productDetailLinks[product.attributes.sku]) {
						product.attributes.details_link = productDetailLinks[product.attributes.sku];
					}

					return product;
				});
				const includesAll = data.length === sortListAll.length;
				let sortList = sortListAll;

				if (!includesAll) {
					sortList = sortListNotAll;
				}

				data.sort((a, b) => {
					const aIndex = sortList.indexOf(a.attributes.sku);
					const bIndex = sortList.indexOf(b.attributes.sku);

					if (aIndex === -1) return 1;
					if (bIndex === -1) return -1;

					return aIndex - bIndex;
				});

				return data;
			} catch (error) {
				return [];
			}
		},
		otherProducts() {
			try {
				return this.storeProducts.filter((product) => product.attributes.category_code === 'other');
			} catch (error) {
				return [];
			}
		},
		litesProducts() {
			try {
				return this.storeProducts.filter((product) => product.attributes.category_code === 'lites');
			} catch (error) {
				return [];
			}
		},
		booksProducts() {
			try {
				return this.storeProducts.filter((product) => product.attributes.category_code === 'books');
			} catch (error) {
				return [];
			}
		},
		wearablesProducts() {
			try {
				return this.storeProducts.filter((product) => product.attributes.category_code === 'wearables');
			} catch (error) {
				return [];
			}
		},
		hasWearablesProducts() {
			return !!this.wearablesProducts.length;
		},
		membershipProducts() {
			try {
				return this.storeProducts.filter((product) => product.attributes.category_code === 'membership');
			} catch (error) {
				return [];
			}
		},
		hasMembershipProducts() {
			return !!this.membershipProducts.length;
		},
		swagProducts() {
			try {
				return this.storeProducts.filter((product) => product.attributes.category_code === 'swag');
			} catch (error) {
				return [];
			}
		},
		hasSwagProducts() {
			return !!this.swagProducts.length;
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
	},
	created() {
		if (!this.isAdminOrCorporate && this.showPublicStore && !this.isAgencySite) {
			this.packData.getPackages();
			if (this.$user.auth() || this.$replicated.replicatedSponsor()) {
				this.prodData.getProducts(this.getStoredCountry());
			}
		}
	},
	methods: {
		discount(product) {
			try {
				if (Object.keys(product.attributes.discount).includes(this.pack.attributes.code_name)) {
					return product.attributes.discount[this.pack.attributes.code_name];
				}
			} catch (error) {
				return {};
			}
			return {};
		},
		hasDiscount(product) {
			return !!(Object.keys(this.discount(product)).length);
		},
		getProductPrice(product, priceKey = 'price') {
			const { price, sku } = product;
			if (PRODUCT_OVERRIDE_PRICE[this.country]) {
				if (PRODUCT_OVERRIDE_PRICE[this.country][sku]?.[priceKey]) {
					return PRODUCT_OVERRIDE_PRICE[this.country][sku][priceKey];
				}
			}

			if (PRODUCT_OVERRIDE_PRICE[sku]?.[priceKey]) {
				return PRODUCT_OVERRIDE_PRICE[sku][priceKey];
			}

			if (price !== '$0.00 USD') {
				return price;
			}

			if (PRODUCT_DEFAULT_PRICE[sku]?.[priceKey]) {
				return PRODUCT_DEFAULT_PRICE[sku][priceKey];
			}

			return price;
		},
		getBundlesInfo() {
			this.bundlesInfo.getPromotions();
		},
		getFreeVirtualEvents() {
			this.freeVirtualEventsInfo.getFreeVirtualEvents();
		},
	},
};
